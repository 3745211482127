import '../css/Splash.css';
import MettleLabsBackgroundImage from '../MettleLabsBackground.png';
import MettleLabsBackgroundGlow from '../mettleLabsBackgroundGlow.png';
import MettleLabsLogo from '../mettlelabsLogoRgbaWhite.png';
const Splash = () => {
    return (
        <div className='Splash'>
            <img src={MettleLabsBackgroundImage} alt="static" className='background-image'></img>
            <img src={MettleLabsBackgroundGlow} alt="Animated" className='fade-image'></img>
            <img src={MettleLabsLogo} alt="logo" className='logo'></img>
        </div>
    )
};

export default Splash;