import '../css/NavBar.css';
const NavBar = () => {
    return (
        <nav>
            <a className='nav-home'>Home</a>
            <a className='nav-login'>Login</a>
        </nav>
    )
}

export default NavBar;