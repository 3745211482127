import NavBar from './components/Navbar';
import Splash from './components/Splash';
import './css/App.css';

const App = () => {
   return( 
    <div>
        <NavBar /> 
        <Splash />
    </div>
    )
};

export default App;